<template>
  <div id="app">
    <Header/>
    <router-view></router-view>
    <Footer/>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  metaInfo:{
    meta:[
        {charset: 'utf-8'},
        {name: 'author', content:"Pietro Nuzzo"},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      ]
  },
  components: {
    Header,
    Footer,
  }
}
</script>

<style>
  body{
    background-image: url('./assets/img/background.png') !important;
    background-position: center !important;
    background-color:#232327 !important;
  }
  h1{
    color:#ffffff;
  }
  .border-5{
    border-width: 5px !important;
  }
</style>
