import Vue from 'vue';
import VueRouter from 'vue-router';
import VueInitialsImg from 'vue-initials-img';
import VueMeta from 'vue-meta';
import VueCookies from 'vue-cookies';

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import App from './App.vue';
import router from './router.js';

const errorsTranslation = require ('./translate/errors.json');
const infoTranslation = require ('./translate/info.json');

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueInitialsImg);
Vue.use(VueMeta);
Vue.use(VueCookies);

if(process.env.NODE_ENV == "development"){
    Vue.prototype.$api_url = process.env.VUE_APP_API_URL;
    Vue.$cookies.config('7d','','',false);
}else{
    Vue.prototype.$api_url = process.env.VUE_APP_API_URL;
    Vue.$cookies.config('1d','','',true);
}

Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f);
      }
    }
    window.addEventListener('scroll', f);
  }
})

new Vue({
  router,
  render: h => h(App),
  methods: {
    goToElement: function (path, element_id) {
      var current_path = this.$route.path;
      if (current_path == path) {
        var conTop = document.getElementById(element_id).offsetTop;
        window.scrollTo(0, conTop - (window.innerHeight / 2));
      } else {
        router.replace(path)
          .then(function () {
            var new_conTop = document.getElementById(element_id).offsetTop;
            window.scrollTo(0, new_conTop - (window.innerHeight / 2));
          })
      }
    },
    hideFromBackoffice: function (){
        var hide;
        this.$route.path.includes("/recovery") || this.$route.path == '/login' || this.$route.path == '/adminarea' ? hide = false : hide = true;
        return hide;
    },
    responseTranslator: function (_value,_data){
        var translator = {};
        switch(_value) {
            case 'infos':
                translator = infoTranslation;
                break;
            case 'errors':
                translator = errorsTranslation;
                break;
        }
        _data.forEach((value,index) => {
            const translated = translator[_data];
            if (translated != undefined){
                _data[index] = translated;
            }
        })
        return _data;
    }
  }
}).$mount('#app')
