<template>
    <div class="container-fluid m-0 p-0">
        <div class="cotainer-fluid p-5 d-flex flex-column justify-content-center" style="height:100vh;">
            <div class="d-flex justify-content-center">
                <div style="max-width:1200px;" class="row">
                    <div class="col-12 col-md-6 d-flex justify-content-center">
                        <div class="d-flex flex-column justify-content-center">
                        <img style="width:100%; height:auto; background-position:center;" src="../assets/img/logo-cropped-dark.png" alt="hackintosh-italia-logo">
                        </div>
                    </div>
                    <div class="col-12 col-md-6 d-flex">
                        <div class="col-12 d-flex flex-column justify-content-center">
                            <h1 class="mt-4">HACKINTOSH ITALIA</h1>
                            <p class="text-white">macOS su hardware non Apple</p>
                            <div class="row">
                                <a class="flex-fill m-2 " href="https://paypal.hackintoshitalia.it" target="_blank">
                                    <button class="w-100 p-3 btn btn-primary">DONAZIONI</button>
                                </a>
                                <a class="m-2 flex-fill" href="" v-on:click.prevent="$root.goToElement('/','contatti')">
                                    <button class="w-100 p-3 btn btn-success">CONTATTI</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-5 p-0 pt-4 container-fluid bg-white">
            <div class="text-center">
                <h2 id="contatti" class="mt-5">Contatti</h2>
            </div>
            <div class="d-flex justify-content-center">
                <div style="max-width:1200px;" class="w-100 row mt-5 m-0 d-flex justify-content-center">
                    <div class="shadow m-2 col-10 col-md-3 p-4 d-flex flex-column text-center justify-content-center">
                        <i class="fab fa-telegram-plane fa-4x"></i>
                        <h5>Gruppo Telegram</h5>
                        <div class="d-flex justify-content-center">
                            <a href="https://telegram.hackintoshitalia.it" target="_blank">
                                <button class="btn btn-primary">ENTRA</button>
                            </a>
                        </div>
                    </div>
                    <div class="shadow m-2 col-10 col-md-3 p-4 d-flex flex-column text-center justify-content-center">
                        <i class="fab fa-instagram fa-4x"></i>
                        <h5>Instagram</h5>
                        <div class="d-flex justify-content-center">
                            <a href="https://instagram.hackintoshitalia.it" target="_blank">
                                <button class="btn btn-primary">PROFILO</button>
                            </a>
                        </div>
                    </div>
                    <div class="shadow m-2 col-10 col-md-3 p-4 d-flex flex-column text-center justify-content-center">
                        <i class="fab fa-discord fa-4x"></i>
                        <h5>Server Discord</h5>
                        <div class="d-flex justify-content-center">
                            <a href="https://discord.hackintoshitalia.it" target="_blank">
                                <button class="btn btn-primary">ENTRA</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div style="background-color:#f6f6f6" class="mt-5">
                <div class="col-12">
                    <h2 id="sponsors" class="pt-5 text-center">Collaborazioni & Sponsorizzazioni</h2>
                </div>
                <div class="col-12 d-flex justify-content-center">
                    <div style="max-width:1200px" class="row">
                        <div class="col-12 col-md-6">
                            <div class="shadow bg-white rounded m-5 p-5 align-self-center">
                                <img class="mt-3" style="width:100%; height:auto;" alt="logo-utopia" src="../assets/img/utopia-logo.png">
                                <h4>Utopia Team</h4>
                                <p>Share your knowledge. Get the best from your rig.</p>
                                <hr>
                                <i class="mb-3 fab fa-github fa-1x text-primary"></i>
                                <span class="ml-2 text-primary"><a href="https://utopia-team.github.io" target="_blank">github</a></span>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="shadow bg-white rounded m-5 p-5 align-self-center">
                                <img class="mt-3" style="width:100%; height:auto;" alt="logo-utopia" src="../assets/img/logo-rload-eu.png">
                                <h4>Rload.eu</h4>
                                <p>Blog e Forum sull'informatica.</p>
                                <hr>
                                <i class="mb-3 fas fa-globe fa-1x text-primary"></i>
                                <span class="ml-2 text-primary"><a href="https://rload.eu/" target="_blank">website</a></span>
                                <br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="  d-flex flex-column justify-content-center">
                <div class="col-12 p-5 text-center">
                    <h2>Chi Siamo</h2>
                    <div class="d-flex justify-content-center">
                        <p style="max-width:600px">Hackintosh Italia è un gruppo fondato su Telegram nel 17 Agosto 2018 per supportare la scena Hackintosh in Italia ed aiutare chiunque voglia avvicinarvisi.</p>
                    </div>
                </div>
                <div class="d-flex flex-wrap pr-5 pl-5 pb-5 justify-content-center">
                    <div style="max-width:1200px" class="flex-grow-1 row">
                        <div v-for="(moderator) in moderators" v-bind:key="moderator.id" class="col-6 col-md-4 text-center mt-2">
                            <img v-if="(moderator.photo_data !== false && moderator.photo_data !== '')" style="width:120px; height:120px;" class="border border-5 border-primary rounded-circle" :src='moderator.photo_data'>
                            <vue-initials-img :name="moderator.user_name" :size=120 v-else style="width:120px; height:120px;" class="border border-5 border-primary rounded-circle"/>
                            <h4 class="moderator-name mt-2">@{{moderator.user_name}}</h4>
                            <p>{{moderator.role}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'Home',
    metaInfo:{
        title: 'Hackintosh Italia',
        titleTemplate:'%s - Home',
        meta:[
            {charset: 'utf-8'},
            {name: 'keywords', content:"Hackintosh, Italia, Home"},
            {name: 'description', content:'Hackintosh Italia è un gruppo fondato su Telegram nel 17 Agosto 2018 per supportare la scena Hackintosh in Italia ed aiutare chiunque voglia avvicinarvisi.'}
        ]
    },
    data: function(){
        return {
            moderators: '',
        }
    },
    beforeMount() {
        this.fetchModerators();
    },
    methods:{
        fetchModerators(){
            const moderators_list = [
                { id: '1010776720', user_name: 'AnimaMusic1998', role:'FOUNDER & GUIDE', photo_data: ''},
                { id: '1740221799', user_name: 'dreamwhitedev', role: 'SUPPORTER & GUIDE', photo_data: '' },
                { id: '0', user_name: 'marianopela', role: 'HELPER', photo_data: '' },
                { id: '0', user_name: 'Exusr', role: 'WEB' , photo_data: '' },
                { id: '179737193', user_name: 'fill0r4', role: 'SUPPORTER', photo_data: '' },
                { id: '523568123', user_name: 'AlessandroAliens', role: 'SUPPORTER', photo_data: '' },
                { id: '126233436', user_name: 'maydayalaska', role: 'SUPPORTER & GUIDE', photo_data: '' },
                { id: '79077354', user_name: 'xLidoni', role: 'SUPPORTER', photo_data: '' },
                { id: '1206099029', user_name: 'teodenetto1', role: 'MERCATINO', photo_data: ''},
                { id: '0', user_name: 'Damian_Drake', role: 'SUPPORTER', photo_data: '' },
                { id: '0', user_name: 'Gabriele', role: 'SUPPORTER', photo_data: '' },
                { id: '951283219', user_name: 'Rt5340', role: 'SUPPORTER', photo_data: '' },
                { id: '717943919', user_name: 'TuxInside18', role: 'SUPPORTER', photo_data: '' },
                { id: '162225015', user_name: 'ItzTravelInTime', role:'DEVELOPER', photo_data: '' },
            ]
            moderators_list.forEach(async(moderator) => {
                async function getData(mod,_this){
                    const res = await fetch(`${_this.$api_url}/api/photofetcher.php?user_id=${mod.id}`);
                    if(res.status != 200){
                        return '';
                    }
                    const json = await res.json();
                    return `data:image/${json.extension};base64,${json.photo}`;
                }
                const data = await getData(moderator,this);
                moderator.photo_data = data;
            });

            this.moderators = moderators_list;
        }
    }
}
</script>

<style>
    @media (max-width: 576px){
        .moderator-name{
            font-size: 14px;
        }
    }
</style>
