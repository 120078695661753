import VueRouter from 'vue-router'

import Home from './pages/Home';

export const router = new VueRouter({
    name: 'Router',
    routes: [{
        path: '/',
        name: 'home',
        component: Home
    }, {
        path: '/introduzione',
        name: 'introduzione',
        component: () => import( /* webpackChunkName: "introduzione" */ "./pages/Introduzione")
    }, {
        path: '/assistenza',
        name: 'assistenza',
        component: () => import( /* webpackChunkName: "assistenza" */ "./pages/Assistenza")
    }, {
        path: '/guide',
        name: 'guide',
        component: () => import(/* webpackChunkName: "guide"*/ "./pages/Guide")
    }, {
        path: '/guide/:slug',
        name: 'guida',
        component: () => import(/* webpackChunkName: "guide"*/ "./pages/Guida")
    }, {
        path: '/login',
        name: 'login',
        component: () => import( /* webpackChunkName: "login"*/ "./pages/backoffice/Login")
    }, {
        path: '/adminarea',
        name: 'adminarea',
        component: () => import( /* webpackChunkName: "adminarea"*/ "./pages/backoffice/AdminArea")
    },{ 
        path: '/recovery/:recovery_key',
        name: 'recovery',
        component: () => import( /*webpackChunkName: "recovery"*/ "./pages/backoffice/Recovery")
    }, /*NOT FOUND*/ {
        path: '*',
        name: 'notfound',
        component: () => import( /* webpackChunkName: "notfound" */ "./pages/NotFound")
    }],
    mode: 'history'
});

export default router;