<template>
    <div v-show="$root.hideFromBackoffice()" v-bind:class="{'white-menu':isWhite,'black-menu':isBlack, 'shadow':isBlack}" v-scroll="handleScroll" class="container-fluid fixed-top">
        <Slide right :closeOnNavigation="true">
            <div class="d-flex">
                <div class="text-center" style="width:32px;">
                    <i class="text-light fas fa-home mt-1"></i>
                </div>
                <router-link class="menu-link ml-2 text-light" to="/"><b>HOME</b></router-link>
            </div>
            <div class="d-flex">
                <div class="text-center" style="width:32px;">
                    <i class="text-light fas fa-info mt-1"></i> 
                </div>
                <router-link class="menu-link ml-2 text-light" to="/introduzione"><b>INTRODUZIONE</b></router-link>
            </div>
            <div class="d-flex">
                <div class="text-center" style="width:32px;">
                    <i class="text-light fas fa-question mt-1"></i> </div>
                <a class="menu-link ml-2 text-light" href="https://github.com/HackintoshItalia/FAQ"><b>FAQ</b></a>
            </div>
            <div class="d-flex">
                <div class="text-center" style="width:32px;">
                    <i class="text-light fas fa-book mt-1"></i> 
                </div>
                <router-link class="menu-link ml-2 text-light" to="/guide"><b>GUIDE</b></router-link>
            </div>
            <div class="d-flex">
                <div class="text-center" style="width:32px;">
                    <i class="text-light fas fa-desktop mt-1"></i>
                </div>
                <router-link class="menu-link ml-2 text-light" to="/assistenza"><b>ASSISTENZA</b></router-link>
            </div>
            <div class="d-flex">
                <div class="text-center" style="width:32px;">
                    <i class="text-light fas fa-address-card mt-1"></i> 
                </div>
                <a class="menu-link ml-2 text-light" href="" v-on:click.prevent="$root.goToElement('/','contatti')"><b>CONTATTI</b></a>
            </div>
            <div class="d-flex">
                <div class="text-center" style="width:32px;">
                    <i class="text-light fas fa-donate mt-1"></i> 
                </div>
                <a class="menu-link ml-2 text-light" href="" v-on:click.prevent="$root.goToElement('/introduzione','donazioni')"><b>DONAZIONI</b></a>
            </div>
        </Slide>
        <div v-if="isWhite" class="row">
            <router-link to='/'>
                <img style="width:64px; height:64px;" class="m-3" src="../assets/img/logo-cropped-dark.png" alt="hackintosh_italia_logo">
            </router-link>
        </div>
        <div v-else class="row">
            <router-link to='/'>
                <img style="width:64px; height:64px;" class="m-3" src="../assets/img/logo-cropped-light.png" alt="hackintosh_italia_logo">
            </router-link>
        </div>
    </div>
</template>

<script>
import { Slide } from 'vue-burger-menu'

export default {
    name: 'Header',
    data: function(){
        return {
            //TODO: provare se è possibile utilizzare una funzione al posto delle 2 variabili
            'isWhite':true,
            'isBlack':false,
        }
    },
    methods:{
        handleScroll: function () {
            if (window.scrollY > 50) {
                this.isWhite = false;
                this.isBlack = true;
            }
            if(window.scrollY < 50){
                this.isWhite = true;
                this.isBlack = false;
            }
        }
    },
    components:{
        Slide
    }
}
</script>

<style>
    .black-menu{
        background-color: #ffffff;
    }
    .white-menu .bm-burger-bars {
        background-color: #ffffff !important;
    }
    .bm-burger-bars{
        background-color: #000000 !important;
    }
    .bm-menu{
        background-color: #000000 !important;
    }
    .menu-link:hover{
        text-decoration: none;
    }
</style>