<template>
    <div v-show="$root.hideFromBackoffice()" style="background-color:#222222; z-index:1000; position:fixed; bottom:0;" class="container-fluid border border-bottom-0 border-left-0 border-right-0 border-5 border-primary">
        <div class="row-fluid text-center text-white">
            <p class="p-3 m-0">©  2020 Hackintosh Italia. Realizzato con <span class="fas fa-heart"></span> <a class="text-white" href="https://vuejs.org/" target="_blank">Vue.</a></p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer'
}
</script>